import styles from "../../style.module.scss";
import EntryForm from "../../../popups/entryForm";
import fiveStopsImg from "../../../../assets/5Stops.png";
import eightStopsImg from "../../../../assets/8Stops.png";
import tenStopsImg from "../../../../assets/10Stops.png";
import fbImg from "../../../../assets/fbLogo.png";
import instaImg from "../../../../assets/instaLogo.png";
import CancoCard from "../../../../assets/cancoCard.png";
import { useState } from "react";

const TabHome = (props) => {
  const [popup, setPopup] = useState("");
  const signupBtnStyle = {
    backgroundColor: "var(--whiteColor)",
    borderRadius: "20px",
    color: "var(--customBlueColor)",
    border: "none",
    outline: "none",
    width: "100%",
    maxWidth: "160px",
    paddingBlockStart: "4px",
    paddingBlockEnd: "6px",
    height: "40px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    fontSize: '1.8rem',
    margin: '20px auto 0',
  };
  return (
    <>
      <div className={`${styles.tabBox} ${styles.tabHome}`}>
        <div className={styles.tabImgContainer}>
          <div>
            <img
              src={fiveStopsImg}
              alt="5 Stops"
              onClick={() => setPopup("fiveStops")}
            />
          </div>
          <div>
            <img
              src={eightStopsImg}
              alt="8 Stops"
              onClick={() => setPopup("eightStops")}
            />
          </div>
          <div>
            <img
              src={tenStopsImg}
              alt="10 Stops"
              onClick={() => setPopup("tenStops")}
            />
          </div>
        </div>
        <div className={styles.tabContentContainer}>
          <div className={styles.tabContentTitle}>
            <h1>LETS GET SOCIAL!</h1>
            <h4>CANCO IS LOOKING TO "#TAG ALONG" YOUR ADVENTURES</h4>
          </div>
          <p>
            From east coast to west coast, Canco will be with you along the way.
            Summer is a time to enjoy the sun, go camping, hike some of Canada's
            most scenic trails, road-trip to cities and festivals and so much
            more. Fuel up before your adventure at Canco gas, grab some snacks
            for the road and earn Canco cash along the way. Canco is here to{" "}
            <span style={{ color: "var(--highlightColor)" }}>
              #fuelyouradventures
            </span>
          </p>
          <p style={{ marginBlockStart: "30px" }}>
            Tag us on your adventures and follow along on our Summer Road Trip
            to 100 for chances to win prizes and more!
          </p>
          <div className={styles.socialContainer}>
            <div>
              <a href="https://www.facebook.com/cancopetroleum">
                <img src={fbImg} alt="Facebook" />
              </a>
            </div>
            <div>
              <a href="https://www.instagram.com/cancopetroleum">
                <img src={instaImg} alt="Instagram" />
              </a>
            </div>
          </div>
        </div>
        <div className={styles.signupSection}>
          <h2>HAVE YOU SIGNED UP FOR CANCO CASH YET</h2>
          <div className={styles.signupInnerSection}>
            <div>
              <h2>THE CARD THAT SPENDS LIKE REAL CASH</h2>
              <p>
                Earn up to 2% Canco cash on select in-store purchases from any
                Canco Gas locations, One Stops and Canco Supermarkets. Some
                restrictions apply, see in-store for details
              </p>
              {/* <Button label="SIGN UP TODAY" btnStyle={signupBtnStyle} /> */}
              <a href="https://cancopetroleum.ca/Signup" style={signupBtnStyle}>SIGN UP TODAY</a>
              <img src={CancoCard} alt="Canco Card" />
            </div>
          </div>
        </div>
      </div>
      {popup === "fiveStops" ? (
        <EntryForm
          termsCondition={() => {
            setPopup(null);
            props.termsCondition();
          }}
          img={fiveStopsImg}
          closeModal={() => setPopup(null)}
        />
      ) : popup === "eightStops" ? (
        <EntryForm
          termsCondition={() => {
            setPopup(null);
            props.termsCondition();
          }}
          img={eightStopsImg}
          closeModal={() => setPopup(null)}
        />
      ) : popup === "tenStops" ? (
        <EntryForm
          termsCondition={() => {
            setPopup(null);
            props.termsCondition();
          }}
          img={tenStopsImg}
          closeModal={() => setPopup(null)}
        />
      ) : null}
    </>
  );
};

export default TabHome;
