import postCardImgOne from "../../../../assets/rulesPostcardOne.jpg";
import postCardImgTwo from "../../../../assets/rulesPostcardTwo.jpg";
import styles from "../../style.module.scss";

const TabRules = () => {
  return (
    <div className={`${styles.tabBox} ${styles.tabRules}`}>
      <div className={styles.tabImgContainer}>
        <div>
          <img src={postCardImgOne} alt="Post Card" />
          <img src={postCardImgTwo} alt="Post Card" />
        </div>
      </div>
      <div className={styles.tabContentContainer}>
        <div className={styles.tabContentTitle}>
          <h1>RULES</h1>
        </div>
        <p>
          *The Winners will be notified using the information provided at the
          time of entry. The Winners must be current Canco Cash Loyalty card
          holders. In the event any Winner does not respond to such notification
          within three (3) business days, declines a prize for any reason, or
          does not meet the requirements set forth, as determined by Canco, then
          the potential Winner will be disqualified, and the prize may be
          cancelled. Once a potential winner is confirmed as a Winner, prize
          distribution will be promptly coordinated. Except as otherwise
          indicated by the Canco, a Winner must personally take delivery of the
          prize as instructed within thirty (30) days of being notified that
          such prize is available. If a Winner fails to take delivery of the
          prize as instructed, the prize will be deemed forfeited. The Winners
          authorize Canco to use their name, photograph, place of residence, and
          image for publicity purposes without any compensation beyond the prize
          awarded. They agree to have this information posted on Canco's
          websites and social media platforms. All content submitted via tagging
          or messaging on social media may be used by Canco Petroleum.
        </p>
        <ul>
          <li>
            <span>
              Grand Prize of $4000 travel voucher + $1000 prepaid Visa™:
            </span>{" "}
            Contest runs from 12:00 am June 30th, 2023, to 11:59 pm on August
            31st, 2023, in the respective time zones. Two winners will be
            randomly selected. The Winners will be drawn and receive $4000 in
            travel vouchers plus a $1000 prepaid Visa™. The winners will be
            announced Friday, September 8th at 12 pm PST.
          </li>
          <li>
            <span>Biosteel + Toronto Baseball Prize Pack:</span> Contest runs from
            12:00 am June 30th, 2023, to 11:59 pm on August 31st, 2023, in the
            respective time zones. One Winner will receive Biosteel + Toronto
            Baseball Prize Pack sponsored by Biosteel. The winner will be
            announced Friday, September 8th at 12 pm PST.
          </li>
          <li>
            <span>Weekly Canco Cash of $25- $50 Prize:</span> 1 Winner drawn
            weekly from July 1st to August 31st. One winner will be randomly
            selected each week. The Winners will be drawn and receive $25-50
            Canco Cash on their Canco Cash Loyalty Card. The winner must be a
            registered Canco Cash Cardholder. The winners will be announced each
            Friday at 12 pm PST from July 7th to September 1st. )
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TabRules;
