import { useState } from "react";
import { useFormik } from "formik";
import Input from "../input";
import Select from "../select";
import UploadFile from "../uploadFile";
import {
  formInitialValues,
  formValidationSchema,
  numberOfStampsArray,
} from "../../utils/formValidation";
import Button from "../button";

const StopsForm = (props) => {
  const [formData, setFormData] = useState(null);
  const formSubmitHandler = (e) => {
    console.log(e.target);
    props.loader(true);
    fetch("/", {
      body: new FormData(e.target),
      method: "POST",
    })
      .then(() => {
        setTimeout(() => {
          props.loader(false);
        props.success(true);
        console.log("Success");
        }, 5000);
      })
      .catch((error) => {
        console.log(`Failed: ${error}`);
      });
  };
  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: formValidationSchema,
    onSubmit: function (values) {
      console.log(values);
      formSubmitHandler(formData);
    }
  });
  const formBtnStyle = {
    width: "100%",
    fontSize: "1.6rem",
    backgroundColor: "var(--primaryColor)",
    color: "var(--whiteColor)",
    paddingBlock: "1rem",
    border: "none",
    outline: "none",
    borderRadius: "10px",
    maxWidth: "150px",
    letterSpacing: "0.1rem",
  };
  return (
    <>
      <form
        method="POST"
        name="fileForm"
        encType="multipart/form-data"
        data-netlify="true"
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
          setFormData(e);
        }}
      >
        <input type="hidden" name="form-name" value="fileForm" />
        <div>
          <Input
            type="number"
            name="cardNumber"
            placeholder="Canco Cash Card # (Must be Registered)"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.cardNumber}
            inputStyle={{
              borderColor:
                formik.touched.cardNumber && formik.errors.cardNumber
                  ? "red"
                  : "var(--primaryColor)",
            }}
          />
          {formik.touched.cardNumber && formik.errors.cardNumber && (
            <p className="errorText">{formik.errors.cardNumber}</p>
          )}
        </div>
        <div>
          <div>
            <Input
              type="text"
              name="firstName"
              placeholder="First Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
              inputStyle={{
                borderColor:
                  formik.touched.firstName && formik.errors.firstName
                    ? "red"
                    : "var(--primaryColor)",
              }}
            />
            {formik.touched.firstName && formik.errors.firstName && (
              <p className="errorText">{formik.errors.firstName}</p>
            )}
          </div>
          <div>
            <Input
              type="text"
              name="lastName"
              placeholder="Last Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
              inputStyle={{
                borderColor:
                  formik.touched.lastName && formik.errors.lastName
                    ? "red"
                    : "var(--primaryColor)",
              }}
            />
            {formik.touched.lastName && formik.errors.lastName && (
              <p className="errorText">{formik.errors.lastName}</p>
            )}
          </div>
        </div>
        <div>
          <Input
            type="number"
            name="contactNumber"
            placeholder="Contact Number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.contactNumber}
            inputStyle={{
              borderColor:
                formik.touched.contactNumber && formik.errors.contactNumber
                  ? "red"
                  : "var(--primaryColor)",
            }}
          />
          {formik.touched.contactNumber && formik.errors.contactNumber && (
            <p className="errorText">{formik.errors.contactNumber}</p>
          )}
        </div>
        <div>
          <Input
            type="email"
            name="emailAddress"
            placeholder="Email Address"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.emailAddress}
            inputStyle={{
              borderColor:
                formik.touched.emailAddress && formik.errors.emailAddress
                  ? "red"
                  : "var(--primaryColor)",
            }}
          />
          {formik.touched.emailAddress && formik.errors.emailAddress && (
            <p className="errorText">{formik.errors.emailAddress}</p>
          )}
        </div>
        <div>
          <Select
            name="numberOfStamps"
            defaultText="How many stamps have you collected"
            style={{
              borderColor:
                formik.touched.numberOfStamps && formik.errors.numberOfStamps
                  ? "red"
                  : "var(--primaryColor)",
            }}
            option={numberOfStampsArray}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.numberOfStamps}
          />
          {formik.touched.numberOfStamps && formik.errors.numberOfStamps && (
            <p className="errorText">{formik.errors.numberOfStamps}</p>
          )}
        </div>
        <div>
          <UploadFile
            onChange={(e) =>
              formik.setFieldValue("cancoPostCard", e.currentTarget.files[0])
            }
            onBlur={formik.handleBlur}
            value={formik.values.cancoPostCard}
          />
          {formik.touched.cancoPostCard && formik.errors.cancoPostCard && (
            <p className="errorText">{formik.errors.cancoPostCard}</p>
          )}
        </div>
        <div>
          <label>
            I accept the{" "}
            <span
              onClick={props.termsCondition}
              style={{ color: "var(--primaryColor)", cursor: "pointer" }}
            >
              Terms and Conditions
            </span>
          </label>
          <Input
            type="checkbox"
            name="acceptTermsAndCondition"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.acceptTermsAndCondition}
            inputStyle={{
              borderColor:
                formik.touched.acceptTermsAndCondition &&
                formik.errors.acceptTermsAndCondition
                  ? "red"
                  : "var(--primaryColor)",
            }}
          />
          {formik.touched.acceptTermsAndCondition &&
            formik.errors.acceptTermsAndCondition && (
              <p className="errorText">
                {formik.errors.acceptTermsAndCondition}
              </p>
            )}
        </div>
        <Button type="submit" label="SUBMIT" btnStyle={formBtnStyle} />
      </form>
    </>
  );
};

export default StopsForm;
