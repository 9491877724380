import styles from "./style.module.scss";
import { Tabs } from "../components";
import CancoLogo from "../assets/cancoLogo.png";

const LandingPage = (props) => {
  return (
    <>
      <div className={styles.logoContainer}>
        <img src={CancoLogo} alt="Canco Road" />
        <p>#FUELYOURADVENTURES</p>
      </div>
      <Tabs />
    </>
  );
};

export default LandingPage;
