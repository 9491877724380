import { useState, useEffect } from "react";
import styles from "./style.module.scss";

const InstaFeeds = () => {
    const [instaData, setInstaData] = useState([]);
  useEffect(() => {
    fetch(
      "https://graph.instagram.com/me/media?fields=id,media_type,media_url,permalink,timestamp&limit=4&access_token=IGQVJXOE9BazJDWXRFMEctUEFBaDM5dUhzVmZAuRC11ZAndoaE9Ra0ZA6emR4b2xabFVyNVFVbVNocHBxbnRUOHFSUEZAYMVdYb1BUeFdfWVZAIa2lnU2lKc3dPS2dYQ2kwUWdiSGc0THNvT3AyVjJMdEotbQZDZD"
    ).then(response => {
        return response.json();
    }).then(response => {
        console.log('reponse', response);
        setInstaData(response.data);
    })
  }, []);
//   console.log('instaData', instaData);
  return (
    <div className={styles.feedContainer}>
      {
        instaData.map((feed, i) => {
            if(feed.media_type === 'IMAGE') {
                return <div>
                    <a href={feed.permalink}><img src={feed.media_url} key={i} alt="Instagram Feed"/></a>
                </div>
            } else if(feed.media_type === 'VIDEO') {
                return <div>
                    <a href={feed.permalink}><video
                width='100%'
                height='100%' 
                src={feed.media_url} 
                type="video/mp4" 
                controls playsInline>
            </video></a>
                </div>
            } else {
                return <div>
                <a href={feed.permalink}><img src={feed.media_url} key={i} alt="Instagram Feed"/></a>
            </div>
            }
        })
      }
    </div>
  );
};

export default InstaFeeds;
