import React, { useState } from "react";
import ReactDOM from "react-dom";
import styles from "./style.module.scss";
import StopsForm from "../../stopsForm";
import Loader from "../../loader";

const PopupBg = () => {
  return <div className={styles.popupBg}></div>;
};

const PopupBody = (props) => {
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState(false);
  return (
    <div className={styles.popupContainer}>
      {loader ? (
        <>
          <Loader />
          <h2
            style={{
              marginTop: "65px",
              fontSize: "30px",
            }}
          >
            Please wait..
          </h2>
        </>
      ) : (
        <div className={styles.popupInner}>
          <div
            className={styles.crossContainer}
            onClick={() => props.closeModal()}
          >
            <svg
              fill="#000000"
              width="800px"
              height="800px"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 14.545L1.455 16 8 9.455 14.545 16 16 14.545 9.455 8 16 1.455 14.545 0 8 6.545 1.455 0 0 1.455 6.545 8z"
                fillRule="evenodd"
              />
            </svg>
          </div>
          {success ? (
            <div className={styles.formSubmitText}>
              <h2>Thank you for entering.</h2>
              <p>
                Ride along our summer road trip and enter to win more prizes{" "}
                <a
                  href="https://www.instagram.com/cancopetroleum"
                  style={{ color: "var(--highlightColor)" }}
                >
                  @cancopetroleum
                </a>
                !
              </p>
            </div>
          ) : (
            <>
              <div className={styles.formImgContainer}>
                <img src={props.img} alt="Entry Form" />
              </div>
              <StopsForm
                loader={(val) => setLoader(val)}
                success={(val) => setSuccess(val)}
                termsCondition={props.termsCondition}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

const EntryForm = (props) => {
  return (
    <>
      {ReactDOM.createPortal(
        <PopupBody
          img={props.img}
          closeModal={props.closeModal}
          termsCondition={props.termsCondition}
        />,
        document.getElementById("popupBlock")
      )}
      {ReactDOM.createPortal(
        <PopupBg />,
        document.getElementById("popupBlock")
      )}
    </>
  );
};

export default EntryForm;
