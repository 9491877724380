import * as Yup from "yup";

export const formInitialValues = {
  cardNumber: "",
  firstName: "",
  lastName: "",
  emailAddress: "",
  contactNumber: "",
  numberOfStamps: "",
  cancoPostCard: "",
  acceptTermsAndCondition: "",
};

export const formValidationSchema = Yup.object({
  cardNumber: Yup.number().required('This is a required field'),
  firstName: Yup.string()
    .trim()
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
      "Only alphabets are allowed"
    )
    .required("This is a required field"),
  lastName: Yup.string()
    .trim()
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
      "Only alphabets are allowed"
    )
    .required("This is a required field"),
    emailAddress: Yup.string()
    .trim()
    .email("Enter a valid email")
    .required("This is a required field"),
    contactNumber: Yup.string()
    .min(10, "Phone number must be 10 digits")
    .max(10, "Phone number must be 10 digits ")
    .required("This is a required field"),
    numberOfStamps: Yup.string().required("This is a required field"),
    cancoPostCard: Yup.mixed().required("This is a required field"),
    acceptTermsAndCondition:  Yup.bool().oneOf([true], 'You need to accept the terms').required('You need to accept the terms'),
});

export const numberOfStampsArray = [
  {
    value: "1",
    label: "1",
  },
  {
    value: "2",
    label: "2",
  },
  {
    value: "3",
    label: "3",
  },
  {
    value: "4",
    label: "4",
  },
  {
    value: "5",
    label: "5",
  },
  {
    value: "6",
    label: "6",
  },
  {
    value: "7",
    label: "7",
  },
  {
    value: "8",
    label: "8",
  },
  {
    value: "9",
    label: "9",
  },
  {
    value: "10",
    label: "10",
  },
]
