import styles from "./style.module.scss";

const Loader = () => {
  return (
    <div className={styles.handContainer}>
      <div className={styles.handOne}></div>
      <div className={styles.handOne}></div>
      <div className={styles.handOne}></div>
      <div className={styles.handOne}></div>
      <div className={styles.handTwo}></div>
      <div className={styles.handThree}></div>
    </div>
  );
};

export default Loader;
