import "./App.scss";
import LandingPage from "./landingPage";

function App() {
  return (
    <div className="appContainer">
      <div className="pageContainer">
        <div className="pageInnerContainer">
          <LandingPage />
        </div>
      </div>
    </div>
  );
}

export default App;
