import InstaFeeds from "../../../InstaFeeds";
import adventureImg from "../../../../assets/adventure.png";
import fbImg from "../../../../assets/fbLogo.png";
import instaImg from "../../../../assets/instaLogo.png";
import styles from "../../style.module.scss";

const TabAdventure = () => {
  return (
    <div className={`${styles.tabBox} ${styles.tabAdventure}`}>
      <div className={styles.tabImgContainer}>
        <div>
          <img src={adventureImg} alt="5 Stops" />
        </div>
      </div>
      <div className={styles.tabContentContainer}>
        <div className={styles.tabContentTitle}>
          <h1>FOLLOW THE ADVENTURE</h1>
          <h4>
            WHEREVER THE SUMMER TAKES YOU, CANCO IS HERE TO #FUELYOURADVENTURES
          </h4>
        </div>
        <p>
          From east coast to west coast, Canco will be with you along the way.
          Summer is a time to enjoy the sun, go camping, hike some of Canada's
          most scenic trails, road-trip to cities and festivals and so much
          more. Fuel up before your adventure at Canco gas, grab some snacks for
          the road and earn Canco cash along the way.
        </p>
        <p style={{ marginBlockStart: "30px" }}>
          Tag us on your adventures and follow along on our Summer Road Trip to
          100 for chances to win prizes and more!
        </p>
        <div className={styles.socialContainer}>
          <div>
            <a href="https://www.facebook.com/cancopetroleum">
              <img src={fbImg} alt="Facebook" />
            </a>
          </div>
          <div>
            <a href="https://www.instagram.com/cancopetroleum">
              <img src={instaImg} alt="Instagram" />
            </a>
          </div>
        </div>
      </div>
      <div className={styles.instaContainer}>
        <div className={styles.instaInnerBlock}>
          <InstaFeeds />
          {/* <img src={instaImg} alt="Instagram" />
          <div>
            <h2>INSTAGRAM FEED</h2>
            <h2>@cancopetroleum</h2>
          </div> */}
        </div>
      </div>
      <div className={styles.hashTagContainer}>
        <h2>#FUELYOURADVENTURES</h2>
      </div>
    </div>
  );
};

export default TabAdventure;
