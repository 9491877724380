const Button = (props) => {
  return (
    <button
      type={props.type || "button"}
      onClick={props.onClick}
      style={props.btnStyle}
    >
      {props.label}
    </button>
  );
};

export default Button;
