import { useState } from "react";
import styles from "./style.module.scss";
import Button from "../button";
import { TabHome, TabRules, TabAdventure } from "./tabParts";

const Tabs = () => {
  const [tabDisplay, setTabDisplay] = useState("home");

  const tabBtnStyle = {
    background: "none",
    border: "none",
    paddingBottom: "1rem",
    cursor: "pointer",
  };

  return (
    <div className={styles.tabContainer}>
      <div className={styles.tabHeader}>
        <Button
          label="HOME"
          onClick={() => setTabDisplay("home")}
          btnStyle={tabBtnStyle}
        />
        <Button
          label="TERMS AND CONDITIONS"
          onClick={() => setTabDisplay("rules")}
          btnStyle={tabBtnStyle}
        />
        <Button
          label="FOLLOW THE ADVENTURE"
          onClick={() => setTabDisplay("adventure")}
          btnStyle={tabBtnStyle}
        />
      </div>
      {/* <div className={styles.tabDisplayContainer}></div> */}
      {tabDisplay === "home" ? (
        <TabHome termsCondition={() => setTabDisplay("rules")}/>
      ) : tabDisplay === "rules" ? (
        <TabRules />
      ) : tabDisplay === "adventure" ? (
        <TabAdventure />
      ) : null}
    </div>
  );
};

export default Tabs;
