const Select = (props) => {
    return <select
    name={props.name}
    id={props.name}
    onChange={props.onChange}
    onBlur={props.onBlur}
    value={props.value}
    style={props.style}
    className="defaultSelect"
  >
    <option defaultValue={""}>{props.defaultText}</option>
    {props.option.map((val, i) => {
      return <option value={val.value} key={i}>{val.label}</option>
    })}
  </select>
}

export default Select;